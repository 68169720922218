import '../styles/banner.scss';
import { useTranslation } from 'react-i18next'; 

export const Banner = () => {
  const [t] = useTranslation("global")

  return <div className='tecnology'>
    <div id="tecnology"></div>
    <div className='inner-tecnology'>
      <h2>{t("banner.title")}</h2>
      <p>{t("banner.text")}</p>
    </div>
  </div>
  
}
