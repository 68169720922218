import '../styles/howItWorks.scss';
import check from '../assets/check.svg';
import service from '../assets/Servicios.png';
import { useTranslation } from 'react-i18next'; 

export const HowItWorks = () => {
  const [t] = useTranslation("global")

  return <div className='how-it-works'>
    <div id="how-it-works"></div>
    <div className='inner-how-it-works'>
      <div className='hiw-img'>
        <img src={service} alt="" />
      </div>
      <div className='hiw-text'>
        <h2>{t("service.title")}</h2>
        <p>{t("service.text")}</p>
        <div className='hiw-items'>
          <div className='item'>
            <img src={check} alt="" />
            <div className='item-text'>
              <h3>{t("service.item1")}</h3>
              <p>{t("service.text1")}</p>
            </div>
          </div>
          <div className='item'>
            <img src={check} alt="" />
            <div className='item-text'>
            <h3>{t("service.item2")}</h3>
              <p>{t("service.text2")}</p>
            </div>
          </div>
          <div className='item'>
            <img src={check} alt="" />
            <div className='item-text'>
            <h3>{t("service.item3")}</h3>
              <p>{t("service.text3")}</p>
            </div>
          </div>
          <div className='item'>
            <img src={check} alt="" />
            <div className='item-text'>
            <h3>{t("service.item4")}</h3>
              <p>{t("service.text4")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
}
