import { Routes, Route } from 'react-router-dom';
import { Home } from '../pages/home';

export const Main = () => {
  return (
    <Routes>
      <Route index element={<Home/>}></Route>
      <Route path="home" element={<Home/>}></Route>
    </Routes>
  )
}
