import { AboutUs } from '../components/aboutUs';
import { Contact } from '../components/contact';
import { Cover } from '../components/cover';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { HowItWorks } from '../components/howItWorks';
import { Banner } from '../components/banner';

export const Home = () => {
  return <div>
    <Header/>
    <Cover/>
    <Banner />
    <HowItWorks />
    <AboutUs />
    <Contact />
    <Footer />
  </div>
}
