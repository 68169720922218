import '../styles/header.scss';
import { HashLink as Link } from 'react-router-hash-link';
import { HamburgerSlider } from 'react-animated-burgers';
import { useState, useEffect } from 'react';
import logo from '../assets/CF_sf.svg';
import { useTranslation } from 'react-i18next'; 

export const Header = () => {
  const [ isActive, setIsActive ] = useState(false)
  const toggleButton = () => setIsActive(!isActive)

  const [t, i18n] = useTranslation("global")

  const [headerActive, setHeaderActive] = useState(false)

  const listenScrollEvent = () => {
    if (window.scrollY > 2) {
      setHeaderActive(true)
    } else {
      setHeaderActive(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  })

  const classes = headerActive ? 'header active' : 'header'

  return (
    <div className={classes}>
      <div className='inner-header'>
        <div className='brand'>
          <Link to='/'><img src={logo} alt="" /></Link>
        </div>
        <div className='hamburguer'>
          <HamburgerSlider 
            isActive={isActive} 
            toggleButton={toggleButton} 
            buttonColor="transparent" 
            barColor='#222' 
          />
          </div>
        <div className={`navbar ${isActive ? 'show-nb' : ''}`}>
          <div className='nb-link'>
            <Link to='/home/#how-it-works' smooth className='links' onClick={toggleButton}>{t("header.how-it-works")}</Link>
            <Link to='/home/#about-us' smooth className='links' onClick={toggleButton}>{t("header.about-us")}</Link>
            <Link to='/home/#contact' smooth className='links' onClick={toggleButton}>{t("header.contact")}</Link>
          </div>
          <div className='lang'>
            <button className='lang-btn' onClick={() => i18n.changeLanguage("es")}>ES</button>
             <span>|</span>
            <button className='lang-btn' onClick={() => i18n.changeLanguage("en")}>EN</button> 
          </div>
        </div>
      </div>
    </div> 
  )
}
