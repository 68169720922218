import '../styles/aboutUs.scss';
import logo from '../assets/CF_white.svg';
import { useTranslation } from 'react-i18next'; 

export const AboutUs = () => {
  const [t] = useTranslation("global")

  return <div className='about-us'>
    <div id="about-us"></div>
    <div className='inner-about-us'>
      <img src={logo} alt="" />
      <div className='about-us-items'>
        <div className='au-item'>
          <h3>{t("cinco.item1")}</h3>
          <p>{t("cinco.text1")}</p>
        </div>
        <div className='au-item'>
        <h3>{t("cinco.item2")}</h3>
          <p>{t("cinco.text2")} <a href="https://www.bvifsc.vg/regulated-entities/cinco-fund-limited" target='_blank'>{t("cinco.link")}</a></p>
        </div>
        <div className='au-item'>
        <h3>{t("cinco.item3")}</h3>
          <p>{t("cinco.text3")}</p>
        </div>
      </div>
    </div>
  </div>
  
}
