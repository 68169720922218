import { useTranslation } from 'react-i18next';

export const Form = () => {
  const [t] = useTranslation("global")

  return  <form action="/public/controllers/controllerForm.php" method='post'  >
  <label>{t("contact.name")}</label>
  <input 
    type="text" 
    name="name" 
    required
  />
  <label>{t("contact.email")}</label>
  <input 
    type="email" 
    name="email" 
    required
  />
  <label>{t("contact.message")}</label>
  <textarea 
    name="message" 
    required
    >  
  </textarea>
  <button type='submit' className='general-button form-btn'>{t("contact.send")}</button>
</form>
}
