import '../styles/footer.scss';
import logo1 from '../assets/logo_BVI.svg';
import logo2 from '../assets/logo-fab.svg';
import logo3 from '../assets/logo-bulltick.svg';
import logo4 from '../assets/logo-bull.svg';
// import logo4 from '../assets/logo-arival.svg';
import logo5 from '../assets/logo-osl.svg';

export const Footer = () => {
  return <div className='footer'>
    <div className='inner-footer'>
      <div className='f-logos'>
        <a href='https://www.bvifsc.vg/' target='_blank'><img src={logo1} alt="" /></a>
        <a href='https://www.firstambank.com/' target='_blank'><img src={logo2} alt="" /></a>
        <a href='https://osl.com/en/' target='_blank'><img src={logo5} alt="" /></a>
        <a href='https://bullmarketus.com/' target='_blank'><img src={logo4} alt="" /></a>
      </div>
        <div className='right'>© 2022 Cinco Fund Limited</div>
    </div>
  </div>
}
