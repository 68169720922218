import '../styles/cover.scss';
import { useTranslation } from 'react-i18next';
import image from '../assets/cinco_header.png';

export const Cover = () => {
  const [t] = useTranslation("global")

  return <div className='cover'>
    <div className='inner-cover'>
      <div className='text-cover'>
        <h1>{t("cover.cover-title")}<br />{t("cover.second-line")}</h1>
      </div>
      <div className='img-cover'>
        <img src={image} alt="" />
      </div>
    </div>
  </div>
}
