import '../styles/contact.scss';
import support from '../assets/support.svg';
import { useTranslation } from 'react-i18next';
import { Form } from './form';

export const Contact = () => {
  const [t] = useTranslation("global")

  return <div className='contact'>
    <div id="contact"></div>
    <div className='inner-contact'>
      <h2>{t("contact.title")}</h2>
      <div className='contact-container'>
      <div className='support'>
        <div className='s-title'>
          <img src={support} alt="" />
          <h3>{t("contact.support")}:</h3>
        </div>
        <p><strong>{t("contact.email")}: </strong>cinco@cincofunds.com</p>
        <p><strong>{t("contact.phone")}: </strong>+ 1 305 450 1220</p>
        <p><strong>{t("contact.address")}: </strong>323 Sunny Isles Boulevard 7th Floor Sunny Isles, Beach Florida 33160, USA</p>
      </div>
        <div className='form'>
         <Form />
        </div>   
      </div>
    </div>
  </div>
  
}
